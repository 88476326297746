@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


/* Basic styling */
body {
  font-family: 'Arial', sans-serif;
}
.scroll-gradient {
  position: relative;
}
.scroll-gradient::before,
.scroll-gradient::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 40px;
  pointer-events: none;
  z-index: 5;
}
.scroll-gradient::before {
  left: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}
.scroll-gradient::after {
  right: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

/* Scrollbar styling */
.scrollbar-thin {
  scrollbar-width: thin;
}
.scrollbar-thumb-gray-500 {
  scrollbar-color: #6b7280 #d1d5db; /* Thumb and track colors for Firefox */
}
.scrollbar-track-gray-300 {
  scrollbar-color: #d1d5db; /* Track color for Firefox */
}
.scrollbar-thin::-webkit-scrollbar {
  height: 6px; /* For horizontal scrollbar */
}
.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: #6b7280; /* Thumb color */
  border-radius: 4px;
}
.scrollbar-thin::-webkit-scrollbar-track {
  background-color: #d1d5db; /* Track color */
}
/* Make horizontal scrollbar always visible */
#thumbnail-container {
  overflow-x: auto;
  padding: 10px 0;
  scrollbar-width: thin; /* Thin scrollbar for Firefox */
  scrollbar-color: #4b5563 #e5e7eb; /* Dark thumb, light track */

  /* For WebKit browsers */
}
#thumbnail-container::-webkit-scrollbar {
  height: 8px; /* Adjust scrollbar height */
}
#thumbnail-container::-webkit-scrollbar-thumb {
  background-color: #4b5563; /* Thumb color */
  border-radius: 8px; /* Rounded scrollbar */
}
#thumbnail-container::-webkit-scrollbar-track {
  background-color: #e5e7eb; /* Track color */
}
#thumbnail-container::before,
#thumbnail-container::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 40px;
  pointer-events: none;
  z-index: 5;
}
#thumbnail-container::before {
  left: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}
#thumbnail-container::after {
  right: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}/* Force scrollbars to always show */
html {
  scrollbar-gutter: stable; /* Ensures scrollbars take up space, avoids layout shift */
}

#thumbnail-container {
  overflow-x: scroll; /* Always show horizontal scrollbar */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for touch devices */
  scrollbar-width: auto; /* Ensures visible scrollbar for Firefox */
}

#thumbnail-container::-webkit-scrollbar {
  height: 8px; /* Visible scrollbar height */
}

#thumbnail-container::-webkit-scrollbar-thumb {
  background-color: #4b5563; /* Thumb color */
  border-radius: 8px; /* Rounded corners */
}

#thumbnail-container::-webkit-scrollbar-track {
  background-color: #e5e7eb; /* Track color */
}
#thumbnail-container {
  overflow-x: auto; /* Enable scrolling */
  padding-bottom: 12px; /* Add space for "simulated" scrollbar */
  position: relative; /* Needed for pseudo-elements */
}

#thumbnail-container::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 8px; /* Simulate scrollbar track height */
  background-color: #e5e7eb; /* Track color */
  z-index: -1; /* Behind thumbnails */
}